<!-- issues -->

<template>
    <div style = "overflow-y: none; " class = "mainCont">

        <!-- HEADER -->
        <v-app-bar :absolute = "true" app color="#fc32f9" style = " color : #fc32f9; font-stretch: condensed;">
            <v-row no-gutters style = "align-items: center;">
                <v-col cols="auto">
                    <v-btn href="https://www.pandapodium.cc/" style = "padding: 0;" text>
                        <v-img src="/images/pandaPodiumWhiteLogo.png"  max-height="50" style = "color: white; min-width: 200px; margin-top: -5px;" contain></v-img>
                    </v-btn>
                </v-col>
                <v-col v-show="window.width >= 768" cols="auto" style = " display: flex; align-items: center;"  >
                </v-col>
            </v-row>

            <div style = "display: flex; justify-content: flex-start; " :style="{'min-width': window.width >= 768 ? '340px' : '185px'}">

                <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }" >
                        <v-checkbox 
                            v-bind="props"
                            v-if = "window.width >= 768"
                            class="d-inline-flex" 
                            style = "color : white; font-weight:600;" 
                            v-model="enableZoomMode"
                            label="Image Zoom"
                        >
                        </v-checkbox>
                    </template>
                    <span> Hover over image to zoom</span>
                </v-tooltip>


                <v-tooltip  location="bottom">
                    <template v-slot:activator="{ props }" >
                        <v-checkbox
                            v-bind="props" 
                            class="d-inline-flex" 
                            style = "color : white; font-weight: 600;" 
                            v-model="advancedMode" 
                            label="Advanced Mode">
                        </v-checkbox>
                    </template>
                    <span> Show advanced specs</span>
                </v-tooltip>
                
            </div>
        </v-app-bar>

        
        <!-- TITLE (Wheelset Compare) -->
        <!-- Center & slighter larger font & add /images/wheelsetCompare/wheelUI.png-->
        <v-row 
            no-gutters 
            style = "justify-content: center; align-items: center; "
            :style = "{ 'margin-top' : window.width >=768 ? '2rem' : '1rem'}"
        >
            <v-col cols="auto" v-show="window.width >= 768" name="fade" is="transition-group">
                <v-img src="/images/wheelsetCompare/wheelUI.png" max-height="100" style = "color: white; min-width: 100px; padding-right:2rem" contain></v-img>
            </v-col>
            <v-col cols="auto">
                <!-- <h2 style = " ">Wheelset Comparison Tool</h2> -->

                <!-- IF mobile: use h2 ELSE h1 -->
                <h1 v-if = "window.width >= 768" style = "font-size: 2rem; font-weight: 600;">Wheelset Comparison Tool</h1>
                <h2 v-else style = "font-size: 1.5rem; font-weight: 600;">Wheelset Comparison Tool</h2>
            </v-col>
        </v-row>

        <!-- DESCRIPTION -->
        <v-container 
            v-if = "window.width >= 768"
            :style = "{ 'margin-top' : window.width >=768 ? '1rem' : '0rem'}"
            style = "display: flex; justify-content: center; align-items: center; flex-direction: column;"
        >
            Use this tool to compare multiple wheelsets at the same time. Use the search box below to add wheelsets to the list, click column headers to change the sort order, then click the wheelset name to view the wheelset on our website. For more details, you can activate 'advanced mode' with the toggle switch in the top right corner.
            --- If you're still not sure which is the right wheelset for you - Contact Us.
        </v-container>

        <!-- DESCRIPTION(MOBILE) -->
        <v-container 
            v-else 
            style = 
                "display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 10px;
                padding-bottom: 0;"

    >
            <v-dialog 
                max-width="500"
            >
                <template v-slot:activator="{ props: activatorProps }">
                    <v-btn
                    v-bind="activatorProps"
                    color="surface-variant"
                    text="Open Guide"
                    variant="flat"
                    ></v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                    <v-card title="Guide">
                    <v-card-text>
                        Use this tool to compare multiple wheelsets at the same time. Use the search box below to add wheelsets to the list, click column headers to change the sort order, then click the wheelset name to view the wheelset on our website. For more details, you can activate 'advanced mode' with the toggle switch in the top right corner. --- If you're still not sure which is the right wheelset for you - Contact Us.
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                        text="Close"
                        @click="isActive.value = false"
                        ></v-btn>
                    </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-container>

        <!-- MAIN -->
        <v-container 
            :class = "{'nonAdvancedMode':!advancedMode && window.width >= 768}" 
            style = "max-width: 90%;"
            :style = "{ 'margin-top' : window.width >=768 ? '1rem' : '-1rem'}"
        >
            <v-data-table
                v-model="selected"
                :headers="currentHeaders"
                :items= "currentItems"
                :items-per-page="0"
                ref="dataTable"
                show-select
                select-strategy="single"
                item-value = "name"
                :custom-key-sort="customKeySort"
            >
                <template v-slot:[`item.delete`] = "{ item }" >
                    <!-- disable if enableSelectAllWheelsets is true -->
                    <v-icon
                        style = " margin-left: -2rem;"
                        size="small"
                        v-if = "!enableSelectAllWheelsets"
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
                <!-- img template for first col -->
                <template v-slot:[`item.imgLink`]="{ item }">
                    <div :style="{ 'margin-left': window.width >= 768 ? '-0rem' : '-1.5rem' }">
                        <!-- wrap img in link redirecting   to item.link -->
                        <a :href="item.link">
                            <v-img
                                :src="item.imgLink"
                                :max-height="window.width >= 768 ? '100' : '50'"
                                :style="{ 'min-width': window.width >= 768 ? '150px' : '0px' }"
                                style="color: white; max-width: 150px;"
                                contain
                                @mouseenter="enableZoomMode && window.width >=768 &&  setEnlargedPic($event, item.imgLink, item.link)"
                                @mousemove="enableZoomMode && window.width >=768 && checkMouseLeave($event)"
                            >
                            </v-img> 
                        </a>
                        <span class="font-weight-bold">{{ item.shortName }}</span>
                    </div>
                </template>

                <template v-slot:[`item.price`]="{ item }">
                    <div>
                        <span  class="font-weight-bold">{{ item.price }}</span>
                    </div>
                </template>


                <!-- this removes pagination -->
                <template #bottom></template>
            </v-data-table>

            <!-- ADD NEW WHEELS -->
            <v-container>

                <!-- Drop-down -->
                <v-container :style="{ width: window.width >= 768 ? '80%' : '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '30px', marginTop: '0rem' }">
                        <div style = "display : flex;">
                            <v-img src="/images/wheelsetCompare/wheelUI.png" max-height="20" style = "color: white; min-width: 20px;" contain></v-img>
                            <h1 style = "font-size: 1rem; font-weight: 600; margin-left:5px">Add New Pair</h1>
                        </div>
                        
                        <div style = "width: 80%">
                            <!-- when an item is selected, call addWheel func -->

                            <!-- disable if enableSelectAllWheelsets is true -->
                            <v-autocomplete
                                v-if = "window.width >= 768"
                                :disabled = "enableSelectAllWheelsets"
                                :label= "autocompleteLabel"
                                hide-details
                                density="comfortable"
                                :items="computeSearchBar"
                                v-model = "addWheelSelected"
                                :menu-props="{maxHeight: window.width >= 768 ? 300 : 100}"
                                :error = "searchExists"
                                @update:modelValue = "addWheelset"
                                v-model:search="search"
                            >
                            </v-autocomplete>

                            <!-- disable if enableSelectAllWheelsets is true -->
                            <v-select
                                v-else
                                :disabled = "enableSelectAllWheelsets"
                                :label= "autocompleteLabel"
                                hide-details
                                dense
                                :items="computeSearchBar"
                                v-model = "addWheelSelected"
                                :menu-props="{maxHeight: window.width >= 768 ? 300 : 200}"
                                @update:modelValue = "addWheelset"
                                :error = "searchExists"
                            >
                            </v-select>
                        </div>
                </v-container>

                <!-- Sort options -->
                <v-container 
                    :style="{ width: window.width >= 768 ? '80%' : '100%', 'flex-direction' : window.width >= 768 ? 'row' : 'column', gap : window.width >= 768 ? '30px' : '0px'}" 
                    style = "display : flex; justify-content: center; align-items: center;"
                >
                    <div  style = "display : flex;" >
                        <h1 style = "font-size: 1rem; font-weight: 600; margin-left:5px">Search Sort By</h1>
                    </div>
                    <div 
                        :style="{ 'flex-wrap': window.width >= 768 ? 'nowrap' : 'wrap', 'width': window.width >= 768 ? 'auto' : '100vw'}"
                        style = " display : flex; justify-content: center; align-items: center;">
                        <v-checkbox style = "min-width: 84px;" label="Name" :hide-details="true" v-model="NameAtoZSort" ></v-checkbox>
                        <v-checkbox style = "min-width: 107px;" label="Price des " :hide-details="true" v-model="priceHtoLSort" ></v-checkbox>
                        <v-checkbox style = "min-width: 107px;" label="Price asc " :hide-details="true" v-model="priceLtoHSort" ></v-checkbox>
                        <v-checkbox style = "min-width: 121px;" label="Weight asc " :hide-details="true" v-model="weightLtoHSort" ></v-checkbox>
                        <v-checkbox style = "min-width: 121px;" label="Weight des " :hide-details="true" v-model="weightHtoLSort" ></v-checkbox>
                    </div>
                </v-container>

                <!-- Select All Checkbox -->
                <v-container
                    style = "display : flex; justify-content: center; align-items: center; margin-top:-1.5rem;"
                >
                        <v-tooltip location="bottom">
                            <template v-slot:activator="{ props }" >
                                <v-checkbox 
                                    v-bind="props"
                                    class="d-inline-flex" 
                                    style = "font-weight:600;" 
                                    v-model="enableSelectAllWheelsets"
                                    label="Select All"
                                >
                                </v-checkbox>
                            </template>
                            <span> Add all available wheelsets to the comparing table </span>
                        </v-tooltip>
                </v-container>
            </v-container>
        </v-container>

        <!-- Revamped ENLARGED PICS -->
        <div ref = "enlargePic" @mousemove = "checkMouseLeave($event)">
            <div v-for = "(item, index) in items" :key = index>
                <div 
                    class = enlarged-image-container
                    :style ="{'cursor' : hoverClickable ? 'pointer' : 'default'}"
                    style = "display: none; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.5); z-index: 100; align-items: center; justify-content: center;"
                    @click = "hoverImgClick($event)"
                >
                    <img :src="item.imgLink" class="enlarged-image" style = "display: none;">
                </div>
            </div>
        </div>

        <!-- FEEDBACK FORM -->
        <v-footer app :absolute = "true">
            <v-col class="text-center" >
                <FeedbackForm @updateSnackbar="onUpdateSnackbar" />
            </v-col>
        </v-footer>
        
        <!-- SNACK-BAR -->
       <v-snackbar
            v-show = "snackbar.show"
            v-model="snackbar.show"
            :color="snackbar.color"
            timeout="3000"
            bottom
        >
            {{ snackbar.text }}
       </v-snackbar>

    </div>
</template>

<script>
import wheelsetSerive from '@/services/wheelsetService.js';
import FeedbackForm from '@/components/common/FeedbackForm.vue';
export default {
    name: 'WheelsetCompare',
    components: {
        FeedbackForm
    },
    props: {
        ppWheelsetId: {
            type: Number,
            default: null
        }
    },
    data: () => ({
        jsonData: null,
        selected: [],
        searchItems: [],
        defaultSearchItems: [],
        advancedMode: false, 
        enableZoomMode: false,
        addWheelSelected: null,
        NameAtoZSort: true,
        priceHtoLSort: false,
        priceLtoHSort: false,
        weightLtoHSort: false,
        weightHtoLSort: false,
        searchExists: false,
        selectedItemImgLink: null,
        autocompleteLabel: 'Search for wheelset',
        hoverImgBorderXleft: 0,
        hoverImgBorderXright: 0,
        hoverImgBorderYtop: 0,
        hoverImgBorderYbuttom: 0,
        hoverClickable: false,
        hoverItemUrl: null,
        selectedImgElement: null,
        enableSelectAllWheelsets:false,
        search: '',
        window:{
            width: 0,
            height: 0},
        headers: [
            {title: '', value: 'delete', align: 'center', sortable: false},
            {title: '', value: 'imgLink', align: 'center', sortable: false, width: '100px', },
            // {title: '', value: 'plusButton', align: 'center', sortable: false },
            {title: 'Price', key:'price', value: 'price', align: 'center', },
            {title: 'Disc or Rim', value: 'DiscOrRim', align: 'center'},
            {title: 'Weight', value: 'weight', key:'weight', align: 'center', sortable: true},
            {title: 'Clincher or Tubeless or Hookless', value: 'clincherOrTubelessOrHookless', align: 'center'},
            {title: 'Front Spoke Count', value: 'frontSpokeCount', align: 'center', sortable: true},
            {title: 'Front Rim Depth', value: 'frontRimDepth', align: 'center', sortable: true},
            {title: 'Front Rim Inner Width', value: 'frontRimInnerWidth', align: 'center', sortable: true},
            {title: 'Front Rim Outter Width', value: 'frontRimOutterWidth', align: 'center', sortable: true},
            {title: 'Rear Spoke Count', value: 'rearSpokeCount', align: 'center', sortable: true},
            {title: 'Rear Rim Depth', value: 'rearRimDepth', align: 'center', sortable: true},
            {title: 'Rear Rim Inner Width', value: 'rearRimInnerWidth', align: 'center', sortable: true},
            {title: 'Rear Rim Outter Width', value: 'rearRimOutterWidth', align: 'center', sortable: true},
            // {title: 'Freehub Engagement Degrees', value: 'freehubEngagementDegrees', align: 'center', sortable: true},
            {title: 'Rider Weight Limit', value: 'riderWeightLimit', align: 'center', sortable: true},
            {title: 'Spoke', value: 'spokeType', align: 'center', sortable: false},
            {title: 'Warranty', value: 'warranty', align: 'center', sortable: true},
        ],
        items:[],
        itemsCopy: [], // save a backup of copy when user selects all wheelsets
        customKeySort: {
            //remove $ from each string and compare numeric value
            price: (p1, p2) => parseFloat(p1.replace('$', '')) - parseFloat(p2.replace('$', '')),

            // remove g from each string and compare numeric value
            weight: (w1, w2) => parseInt(w1.replace('g', '')) - parseInt(w2.replace('g', '')),
        },
        snackbar: {
            show: false,
            color: 'success',
            text: ''
        },
    }),
    methods: {
        getColor (calories) {
            if (calories > 100) return 'red'
            else if (calories > 50) return 'orange'
            else return 'green'
        },

        // main function to compare items
        compareItems(){
            // make a copy of items array by value
            let comparedItems = JSON.parse(JSON.stringify(this.items));
            // find the selected item by name using this.selected[0]
            let selectedItem = comparedItems.find(item => item.name === this.selected[0]);

            // for every other item in comparedItems, compare every number value to selectedItem, 
            // find difference and alter the value in number format (+ or -)
            // skip the item if it's the same as selectedItem
            comparedItems.forEach(item => {
                if(item.name !== selectedItem.name){
                    for (const [key, value] of Object.entries(item)) {
                        if(selectedItem[key] === null || selectedItem[key] === undefined || selectedItem[key] === '' || selectedItem[key] === -1){
                               // do nothing
                        } else if(value === null || value === undefined || value === '' || value === -1){
                            item[key] = 'N/A';
                        } 
                        // else if the value can be turned from string to number
                        else if(!isNaN(value) && key != "warranty" && key != "ppId" && key != 'id'){
                            let difference = value - selectedItem[key];
                            item[key] = difference;
                        }

                        // if(typeof value === 'number'){
                        //     let difference = value - selectedItem[key];
                        //     item[key] = difference;
                        // }
                    }
                }
            });

            return comparedItems;
        },

        // add unit to the values in items
        formatItems(items){
            // make a copy of items array by value
            let formattedItems = JSON.parse(JSON.stringify(items));

            // for every item in formattedItems, add unit to every number value
            // if not comparing
            if(this.selected.length === 0){
                formattedItems.forEach(item => {
                    for (const [key, value] of Object.entries(item)) {
                        if(value === null || value === undefined || value === '' || value === -1){
                            item[key] = 'N/A';
                        }
                        else if(key === 'price'){
                            // if negative, add - sign in front of $
                            if(value < 0){
                                item[key] = '-$' + Math.abs(value);
                            } else {
                                item[key] = '$' + value;
                            }
                        } 
                        else if (key === 'weight'){
                            item[key] = value + 'g';
                        }
                        else if (key === 'frontRimDepth' || key === 'rearRimDepth'){
                            item[key] = value + 'mm';
                        }
                        else if (key === 'frontRimInnerWidth' || key === 'frontRimOutterWidth' || key === 'rearRimInnerWidth' || key === 'rearRimOutterWidth'){
                            item[key] = value + 'mm';
                        }
                        else if (key === 'freehubEngagementDegrees'){
                            item[key] = value + '°';
                        }
                        else if (key === 'riderWeightLimit'){
                            item[key] = value + 'kg';
                        }
                    }
                });
            } 
            // if comparing, add + to positive number, negative number is already handled
            else {
                // console.log(this.compareItems())
                formattedItems.forEach(item => {
                        for (const [key, value] of Object.entries(item)) {
                            if(value === null || value === undefined || value === '' || value === 'N/A' || value === -1){
                                item[key] = 'N/A';
                            }
                            else if(key === 'price'){
                                // if negative, add - sign in front of $
                                if(value < 0){
                                    item[key] = '-$' + Math.abs(value);
                                } else {
                                    if(item.name !== this.selected[0]){
                                        item[key] = '+$' + value;
                                    }else{
                                        item[key] = '$' + value;
                                    }
                                }
                            } 
                            else if (key === 'weight'){
                                // if weight is negative, don't add + sign
                                if(value < 0){
                                    item[key] = value + 'g';
                                } else {
                                    if(item.name !== this.selected[0]){
                                        item[key] = '+' + value + 'g';
                                    }else{
                                        item[key] = value + 'g';
                                    }
                                }
                            }
                            // do the same for negative value for every other values
                            else if (key === 'frontRimDepth' || key === 'rearRimDepth'){
                                if(value < 0){
                                    item[key] = value + 'mm';
                                } else {
                                    if(item.name !== this.selected[0]){
                                        item[key] = '+' + value + 'mm';
                                    }else{
                                        item[key] = value + 'mm';
                                    }
                                }
                            }
                            else if (key === 'frontRimInnerWidth' || key === 'frontRimOutterWidth' || key === 'rearRimInnerWidth' || key === 'rearRimOutterWidth'){
                                if(value < 0){
                                    item[key] = value + 'mm';
                                } else {
                                    if(item.name !== this.selected[0]){
                                        item[key] = '+' + value + 'mm';
                                    }else{
                                        item[key] = value + 'mm';
                                    }
                                }
                            }
                            else if (key === 'freehubEngagementDegrees'){
                                if(value < 0){
                                    item[key] = value + '°';
                                } else {
                                    if(item.name !== this.selected[0]){
                                        item[key] = '+' + value + '°';
                                    }else{
                                        item[key] = value + '°';
                                    }
                                }
                            }
                            else if (key === 'riderWeightLimit'){
                                if(value < 0){
                                    item[key] = value + 'kg';
                                } else {
                                    if(item.name !== this.selected[0]){
                                        item[key] = '+' + value + 'kg';
                                    }else{
                                        item[key] = value + 'kg';
                                    }
                                }
                            }
                            else if (key === 'frontSpokeCount' || key === 'rearSpokeCount'){
                                if(value < 0){
                                    item[key] = value;
                                } else {
                                    if(item.name !== this.selected[0]){
                                        item[key] = '+' + value;
                                    }else{
                                        item[key] = value;
                                    }
                                }
                            }
                            
                        }
                    }
                );

                // iterate through selected items' value, if the any col is N/A, 
                // check if other item's same col starts with +/-, if so, remove the sign
                let selectedItem = formattedItems.find(item => item.name === this.selected[0]);
                for (const [key, value] of Object.entries(selectedItem)) {
                    if(value === 'N/A' && key !== 'ppId' && key !== 'id'){
                        formattedItems.forEach(item => {
                            if(item.name !== selectedItem.name){
                                if(item[key].startsWith('+') || item[key].startsWith('-')){
                                    item[key] = item[key].substring(1);
                                }
                            }
                        });
                    }
                }
                
            }
            return formattedItems;
        },

        deleteItem(item){
            // find the index of the item in items
            let index = this.items.findIndex(i => i.name === item.name);
            // remove the item from items
            this.items.splice(index, 1);

            // update local storage by id
            // localStorage.setItem('wheelCompareItems', JSON.stringify(this.items));
            let selectedItemsId = this.items.map(item => item.id);
            localStorage.setItem('wheelCompareItemsId', JSON.stringify(selectedItemsId));
        },

        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },

        setEnlargedPic(event,imgLink,urlLink){
            // {
            //     "x": 202.703125,
            //     "y": 238,
            //     "width": 150,
            //     "height": 100,
            //     "top": 238,
            //     "right": 352.703125,
            //     "bottom": 338,
            //     "left": 202.703125
            // }
            // get x and y border of the orignial image, if mouse is outside, hide the enlarged image
            this.hoverImgBorderXright = event.target.getBoundingClientRect().right;
            this.hoverImgBorderXleft = event.target.getBoundingClientRect().left;
            this.hoverImgBorderYtop = event.target.getBoundingClientRect().top;
            this.hoverImgBorderYbuttom = event.target.getBoundingClientRect().bottom;
            this.hoverItemUrl = urlLink;
            this.selectedItemImgLink = imgLink;
            // console.log(imgLink)
            // // // console log src attribute of the img
            // console.log(this.$refs.enlargePic.children)
            // iterate through all the enlarged images and find the one that matches the imgLink
            let enlargedImages = this.$refs.enlargePic.children;
            for(let i = 0; i < enlargedImages.length; i++){
                if(enlargedImages[i].children[0].children[0].src === imgLink){
                    this.selectedImgElement = enlargedImages[i].children[0];
                    
                    // wait half a second before displaying the enlarged image
                    setTimeout(() => {
                        this.selectedImgElement.style.display = 'flex';
                        this.selectedImgElement.children[0].style.display = 'block';
                    }, 100);

                    break;
                } 
            }
        },

        checkMouseLeave(event) {
            if(event.clientX < this.hoverImgBorderXleft || event.clientX > this.hoverImgBorderXright || event.clientY < this.hoverImgBorderYtop || event.clientY > this.hoverImgBorderYbuttom){
                this.selectedImgElement.style.display = 'none';
                this.selectedImgElement.children[0].style.display = 'none';
                this.selectedImgElement = null;
            }

            // if cursor is still on the small image, make cursor a pointer that 
            else{
                this.hoverClickable = true;
            }
            // Check if the mouse is still within the boundaries of the enlarged image
            // const enlargedImageContainer = document.querySelector('.enlarged-image-container');
            // const rect = enlargedImageContainer.getBoundingClientRect();
            // console.log(rect);
            // const mouseX = event.clientX;
            // const mouseY = event.clientY;
            // if (mouseX < rect.left || mouseX > rect.right || mouseY < rect.top || mouseY > rect.bottom) {
            //     // If the mouse is outside the boundaries, hide the enlarged image
            //     this.selectedItemImgLink = null;
            // }
        },

        hoverImgClick(){
            // if the enlarged image is clicked, redirect to the item's link
            if(this.hoverClickable){
                window.open(this.hoverItemUrl, '_blank');
            }
        },

        sortSearchHelper(sortVmodel){
            if(sortVmodel){
                sortVmodel = true;
            }else{
                this.priceLtoHSort = false;
                this.weightLtoHSort = false;
                this.weightHtoLSort = false;
                this.NameAtoZSort = false;
                this.addWheelSelected = null;
                this.searchExists = false;
            }
        },

        addWheelset() {
            let val = this.addWheelSelected;
            // val = ' $' + price + ' ' + weight + 'g' + ' ' + name
            // split val into name, price, weight
            // find the item in jsonData.data that has the same name as name
            // add the item to items
            if(val !== null && val !== undefined && val !== ''){
                // name = all elements concat expect for first 3
                let name = val.split(' ').slice(3).join(' ');
                let selectedItem = this.jsonData.data.find(item => item.name === name);
                if(selectedItem !== null & selectedItem !== undefined){

                    // if selectedItem is already in items, don't add it again, alert user
                    if(this.items.find(item => item.name === selectedItem.name)){
                        this.searchExists = true;
                        this.autocompleteLabel = 'Item already added';
                        this.$nextTick(() => {
                            this.addWheelSelected = null;
                            this.search = '';
                        });

                        // if autocompleteLabel is still 'Item already added' after 2 seconds, reset it
                        setTimeout(() => {
                            if(this.autocompleteLabel === 'Item already added'){
                                this.autocompleteLabel = 'Search for wheelset';
                                this.searchExists = false;
                            }
                        }, 2000);
                    } else {
                        this.$nextTick(() => {
                            // reset the selected value
                            this.addWheelSelected = null;
                            this.searchExists = false;
                            this.search = '';
                            this.autocompleteLabel = 'Search for wheelset';
                        });

                        
                        // ---------------------------------------------
                        // ADD ITEM TO ITEMS 
                        this.items.push(selectedItem);

                        // push all id of selected items to local storage
                        let selectedItemsId = this.items.map(item => item.id);
                        localStorage.setItem('wheelCompareItemsId', JSON.stringify(selectedItemsId));

                        // ---------------------------------------------


                        localStorage.setItem('wheelCompareItems', JSON.stringify(this.items));

                    }
                }
            }

            // let selectedItem = this.jsonData.data.find(item => item.name === val);
            // if(selectedItem !== null & selectedItem !== undefined){
            //     this.items.push(selectedItem);
            //     console.log(selectedItem)
            //     // reset the selected value
            //     this.addWheelSelected = null;
            // }
        },

        onUpdateSnackbar(text){
            this.snackbar.text = text;
            this.snackbar.show = true;
        }
    },
    async mounted() {
        // Code to run when the component is mounted
        document.title = "Wheelset Compare";
        // set to favicon = https://www.pandapodium.cc/wp-content/uploads/2023/05/Panda_Podium_LogoFavicon.png
        const favicon = document.querySelector('link[rel="icon"]');
        favicon.href = "https://www.pandapodium.cc/wp-content/uploads/2023/05/Panda_Podium_LogoFavicon.png";

        // Fetch JSON data
        try {
                const response = await wheelsetSerive.getAllWheelsets()
                this.jsonData = {'data' : response.data.data.results};
                
                // extract price, weight, name, imgLink from jsonData and add to searchItems
                this.searchItems = this.jsonData.data.map(item => {
                    return {
                        name: item.name,
                        imgLink: item.imgLink,
                        price: item.price,
                        weight: item.weight
                    }
                });

                // make a copy of searchItems before sorting
                this.defaultSearchItems = JSON.parse(JSON.stringify(this.searchItems));

                // Set default sorting to NameAtoZSort
                this.$nextTick(() => {
                    this.searchItems.sort((a, b) => (a.name > b.name) ? 1 : -1);
                })
                // set localstorage
                if(localStorage.getItem('wheelCompareItemsId')){
                        let storedItemsId = JSON.parse(localStorage.getItem('wheelCompareItemsId'));
                        this.items = this.jsonData.data.filter(item => storedItemsId.includes(item.id));
                    } else{
                        this.items = [];
                    }

            } catch (error) {
                console.error('Error fetching JSON:', error);
            }

        // Adding wheelset from url param to items, make sure ppWheelsetId is a number 
        if (this.ppWheelsetId && Number.isNaN(this.ppWheelsetId) === false){

            // find item from this.jsonData.data array that has the same ppId as ppWheelsetId
            let paramItem = null;
            this.jsonData.data.forEach(item => {
                if(item.ppId === parseInt(this.ppWheelsetId)){
                    // console.log("here")
                    paramItem = item;
                }
            });

            // if ppId doesn't exist, don't add it to items
            if(paramItem){
                // if the item is not already in items, add it to items
                // console.log(this.items.find(item => item.ppId === this.ppWheelsetId))
                // console.log(this.ppWheelsetId, typeof this.ppWheelsetId)
                // console.log(this.items[0].ppId, typeof this.items[0].ppId)
                if(!this.items.find(item => item.ppId === parseInt(this.ppWheelsetId))){
                    this.items.push(paramItem);

                    // store the id of the selected item in local storage
                    let selectedItemsId = this.items.map(item => item.id);
                    localStorage.setItem('wheelCompareItemsId', JSON.stringify(selectedItemsId));

                }
            }else{
                // console.log('No item found with the ppWheelsetId provided in the URL.');
            }
        } else {
            // console.log('No ppWheelsetId provided in the URL.');
        }

    },
    computed: {
        currentHeaders() {
            // Dynamically select headers based on the state of advancedMode
            if(this.advancedMode){
                return this.headers;
            } else {
                return this.headers.filter(header => 
                header.value !== 'freehubEngagementDegrees' && header.value !== 'riderWeightLimit'
                 && header.value !== 'frontRimOutterWidth'
                 && header.value !== 'rearRimOutterWidth' && header.value !== 'frontSpokeCount' 
                && header.value !== 'rearSpokeCount' && header.value !== 'warranty' && header.value !== 'spokeType');
            }
        },

        currentItems(){
            if(this.selected.length === 0){
                return this.formatItems(this.items);
            } else {
                return this. formatItems (this.compareItems());
            }
        },

        computeSearchBar(){
            // combine searchItems.name and searchItems.price and searchItems.weight
            // store each string in an object with {name: string,disabled: false}
            // let combined = this.searchItems.map(item => {
            //     return {name: item.name + ' $' + item.price + ' ' + item.weight + 'g', disabled: false};
            // });
            // return combined;
            let combined = this.searchItems.map(item => {
                return ' $' + item.price + ' ' + item.weight + 'g' + ' ' + item.name;
            });
            return combined;
        },


    },
    // Other component options go here
    watch: {
        priceHtoLSort: function (val) {
            if(val){
                this.priceLtoHSort = false;
                this.weightLtoHSort = false;
                this.weightHtoLSort = false;
                this.NameAtoZSort = false;
                this.addWheelSelected = null;
                this.searchExists = false;
                this.autocompleteLabel = 'Search for wheelset';
                this.searchItems.sort((a, b) => (a.price > b.price) ? -1 : 1);

                // set sortOption state to priceHtoLSort in local storage
                localStorage.setItem('wheelCompareSortOption', 'priceHtoLSort');
            }
        },

        priceLtoHSort: function (val) {
            if(val){
                this.priceHtoLSort = false;
                this.weightLtoHSort = false;
                this.weightHtoLSort = false;
                this.NameAtoZSort = false;
                this.addWheelSelected = null;
                this.searchExists = false;
                this.autocompleteLabel = 'Search for wheelset';
                this.searchItems.sort((a, b) => (a.price > b.price) ? 1 : -1);

                // set sortOption state to priceLtoHSort in local storage
                localStorage.setItem('wheelCompareSortOption', 'priceLtoHSort');
            }
        },

        weightLtoHSort: function (val) {
            if(val){
                this.priceHtoLSort = false;
                this.priceLtoHSort = false;
                this.weightHtoLSort = false;
                this.NameAtoZSort = false;
                this.addWheelSelected = null;
                this.searchExists = false;
                this.autocompleteLabel = 'Search for wheelset';
                this.searchItems.sort((a, b) => (a.weight > b.weight) ? 1 : -1);

                // set sortOption state to weightLtoHSort in local storage
                localStorage.setItem('wheelCompareSortOption', 'weightLtoHSort');
            }
        },

        weightHtoLSort: function (val) {
            if(val){
                this.priceHtoLSort = false;
                this.priceLtoHSort = false;
                this.weightLtoHSort = false;
                this.NameAtoZSort = false;
                this.addWheelSelected = null;
                this.searchExists = false;
                this.autocompleteLabel = 'Search for wheelset';
                this.searchItems.sort((a, b) => (a.weight > b.weight) ? -1 : 1);

                // set sortOption state to weightHtoLSort in local storage
                localStorage.setItem('wheelCompareSortOption', 'weightHtoLSort');
            }
        },

        NameAtoZSort: function (val) {
            if(val){
                this.priceHtoLSort = false;
                this.priceLtoHSort = false;
                this.weightLtoHSort = false;
                this.weightHtoLSort = false;
                this.addWheelSelected = null;
                this.searchExists = false;
                this.autocompleteLabel = 'Search for wheelset';
                this.searchItems.sort((a, b) => (a.name > b.name) ? 1 : -1);

                // set sortOption state to NameAtoZSort in local storage
                localStorage.setItem('wheelCompareSortOption', 'NameAtoZSort');
            }
        },

        enableZoomMode: function(val){
            // save the state of enableZoomMode to local storage
            localStorage.setItem('wheelCompareEnableZoomMode', val);
        },

        advancedMode: function(val){
            // save the state of advancedMode to local storage
            localStorage.setItem('wheelCompareAdvancedMode', val);
        },

        enableSelectAllWheelsets:function(val){
        // when user selects this option
        // first save a copy of items to itemsCopy
        // then clear items and add all wheelsets to items
        // when user unselects this option, restore items to itemsCopy
            if(val){
                this.itemsCopy = JSON.parse(JSON.stringify(this.items));
                this.items = this.jsonData.data;
            } else {
                this.items = this.itemsCopy;
            }
        },
    },
    created() {

        // Code to run when the component is created

        // set the state of enableZoomMode from local storage
        const storedEnableZoomMode = localStorage.getItem('wheelCompareEnableZoomMode');
        if (storedEnableZoomMode) {
            this.enableZoomMode = JSON.parse(storedEnableZoomMode);
        } else{
            this.enableZoomMode = false;
        }

        // set the state of advancedMode from local storage
        const storedAdvancedMode = localStorage.getItem('wheelCompareAdvancedMode');
        if (storedAdvancedMode) {
            this.advancedMode = JSON.parse(storedAdvancedMode);
        } else{
            this.advancedMode = false;
        }

        // set the state of sortOption from local storage
        const storedSortOption = localStorage.getItem('wheelCompareSortOption');
        if (storedSortOption) {
            if(storedSortOption === 'priceHtoLSort'){
                this.priceHtoLSort = true;
            } else if(storedSortOption === 'priceLtoHSort'){
                this.priceLtoHSort = true;
            } else if(storedSortOption === 'weightLtoHSort'){
                this.weightLtoHSort = true;
            } else if(storedSortOption === 'weightHtoLSort'){
                this.weightHtoLSort = true;
            } else if(storedSortOption === 'NameAtoZSort'){
                this.NameAtoZSort = true;
            }
        }
        

        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>

<style scoped>

/* overwrite font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.no-scroll {
    overflow: hidden;
    padding-inline-end: var(--v-scrollbar-offset);
}

.mainCont{
    font-family: 'Poppins' !important
}

/* Your component styles go here */
.v-data-table th {
  font-weight: bold;
}

.nonAdvancedMode{
    max-width: 90% !important;
}


tr.v-data-table__selected {
background: #7d92f5 !important;
}

.v-data-table {
  table {
    tr {
      th, td {
        padding: 0 0px !important;
      }
    }
  }
}

.enlarged-image-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* semi-transparent background */
}

.enlarged-image {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

.fade-enter-active, .fade-leave-active {
        transition: all 1s;
      }
      .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
      }

</style>