<template>
  <v-card elevation="0" class="mx-auto mainCont" style="border-width: 0px">
    <v-layout>
      <v-app-bar app color="#fc32f9">
        <v-row no-gutters style="align-items: center;">
          <v-col cols="auto">
            <a href="https://www.pandapodium.cc/" style="padding: 0;">
              <v-img src="/images/pandaPodiumWhiteLogo.png" max-height="50" style="min-width: 200px; margin-top: -5px;" contain></v-img>
            </a>
          </v-col>

          <v-col>
            <h2 v-show="isMobile" class="display-2">Wheelset Finder</h2>
          </v-col>
        </v-row>
      </v-app-bar>

      <v-main class="main-content">
        <!-- TITLE -->
        <v-container class="text-center my-5 title-container">
          <v-row>
            <v-col class = "title" v-show="!isMobile">
              <h1 style="font-weight:600">Panda Podium Wheelset Finder</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2 style="text-align: justify; font-weight: 600;">Start with the right wheels</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p style="text-align: justify;">
                What type of bike do you have? Do you ride often? Or are you training for a race? These are important questions to ask when you're trying to make a wheelset match. This tool will help you understand which wheelsets is right for you.
              </p>
            </v-col>
          </v-row>
        </v-container>

        <!-- MAIN CONTENT -->
        <v-container class="main-content-container">

          <!-- Options Sections -->
        <v-container>
          <div v-for="(section, sectionIndex) in sections" :key="sectionIndex" class="option-section">
            <v-row class="option-container section-row align-center" style="flex-wrap: nowrap !important;">
              
              <v-col cols="12" sm="1" class="section-icon">
                <v-icon>{{ section.icon }}</v-icon>
              </v-col>

              <v-col cols="12" sm="2" class="section-title">
                {{ section.title }}

                <v-tooltip v-if="section.tooltip" bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon
                      color="grey lighten-1"
                      v-bind="props"
                      small
                      style="cursor: pointer; margin-left: 5px;"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>{{ section.tooltip }}</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="9" class="options-container">
                <v-row>
                  <v-col v-for="(option, optionIndex) in section.options" :key="optionIndex" sm="5" xs = "5" class="option-col">
                    <v-btn
                      :class="{ selected: section.selectedOption === option }"
                      @click="toggleOption(sectionIndex, option)"
                      class="option-btn"
                      outlined
                    >
                      {{ option }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </div>
        </v-container>

          <!-- Best Matches Section -->
          <v-container v-if="showResults" class="best-matches-section mt-1 section-row">

            <!-- LOADING ICON -->
            <v-row v-if="loading" class="text-center">
              <v-col>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-col>
            </v-row>

            <!-- IF FINISHED LOADING -->
            <template v-if="!loading">
              <!-- PERFECT MATCHES CONTAINER -->
              <v-container v-if="perfectMatch.length > 0" style="padding-top:0px; padding-bottom: 0px;">
                <v-row class="text-center" style="margin-bottom:-6.5px">
                  <v-col>
                    <h3>Perfect Matches</h3>
                  </v-col>
                </v-row>
                <!-- PERFECT MATCHES ROW -->
                <v-row v-for="(wheel, index) in perfectMatch" :key="index" class="match-row align-center" style="background-color:white; background-clip: content-box; margin-bottom:0px; margin-top:0px; padding-top:0px; border-radius: 20px;">
                  <v-col cols="2">
                    <a :href="wheel.link">
                      <v-img :src="wheel.imgLink || 'https://via.placeholder.com/100'" lazy-src="https://via.placeholder.com/100" alt="Wheel image" contain height="100px" width="100px"></v-img>
                    </a>
                  </v-col>
                  <v-col cols="9">
                    <p><strong>Name:</strong> {{ filterValue(wheel.name) }}</p>
                    <p><strong>Price:</strong> {{ formatPrice(wheel.price) }}</p>
                    <p><strong>Type:</strong> {{ filterValue(wheel.DiscOrRim) }}</p>
                    <p><strong>Front Rim Depth(mm):</strong> {{ filterValue(wheel.frontRimDepth) }}</p>
                    <p><strong>Weight(g):</strong> {{ filterValue(wheel.weight) }}</p>
                    <p><strong>Front Rim Inner Width(mm):</strong> {{ filterValue(wheel.frontRimInnerWidth) }}</p>
                  </v-col>
                  <v-col cols="1">
                    <v-btn :href="wheel.link" icon style="box-shadow: none; border: none;">
                      <v-icon>mdi-link</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>

              <!-- BEST MATCHES CONTAINER -->
              <v-container v-if="bestMatches.length > 0" style="padding-top:0px">
                <v-row class="text-center">
                  <v-col style="margin-bottom: 10px">
                    <h3 v-if="perfectMatch.length === 0">We couldn't find any perfect matches but here are the closest results</h3>
                    <h3 v-else>You might also be interested in this/these</h3>
                  </v-col>
                </v-row>
                <v-row v-for="(wheel, index) in bestMatches" :key="index" class="match-row align-center" style="background-color:white; background-clip: content-box; margin-bottom:0px; margin-top:0px; padding-top:0px; border-radius: 20px;">
                  <v-col cols="2">
                    <a :href="wheel.link">
                      <v-img :src="wheel.imgLink || 'https://via.placeholder.com/100'" lazy-src="https://via.placeholder.com/100" alt="Wheel image" contain height="100px" width="100px"></v-img>
                    </a>
                  </v-col>
                  <v-col cols="9">
                    <p><strong>Name:</strong> {{ filterValue(wheel.name) }}</p>
                    <p><strong>Price:</strong> {{ formatPrice(wheel.price) }}</p>
                    <p><strong>Type:</strong> {{ filterValue(wheel.DiscOrRim) }}</p>
                    <p><strong>Front Rim Depth(mm):</strong> {{ filterValue(wheel.frontRimDepth) }}</p>
                    <p><strong>Weight(g):</strong> {{ filterValue(wheel.weight) }}</p>
                    <p><strong>Front Rim Inner Width(mm):</strong> {{ filterValue(wheel.frontRimInnerWidth) }}</p>
                  </v-col>
                  <v-col cols="1">
                    <v-btn :href="wheel.link" icon style="box-shadow: none; border: none;">
                      <v-icon>mdi-link</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </template>

          </v-container>
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
import wheelsetService from '@/services/wheelsetService.js';

export default {
  name: 'WheelsetFinder',
  data() {
    return {
      sections: [
        { title: 'Budget', icon: 'mdi-cash', options: ['$500-$800', '$800-$1200', '>$1200', 'No Preference'], selectedOption: null },
        { title: 'Bike Type', icon: 'mdi-bike', options: ['Road Rim', 'Road Disc', 'Gravel Disc', 'Mountain Disc'], selectedOption: null },
        { title: 'Terrain', icon: 'mdi-terrain', options: ['Flat', 'Hilly', 'Mixed'], selectedOption: null,  tooltip: 'This Option will decide your rim depth.'},
        // { title: 'Wind', icon: 'mdi-weather-windy', options: ['Calm', 'Windy', "I'm not sure"], selectedOption: null, tooltip: 'Is it windy where you ride? Deeper wheels get fewer points in windy conditions.' },
      ],
      perfectMatch: [],
      bestMatches: [],
      jsonData: [],
      loading: false,
      showResults: false,
      isMobile: window.innerWidth <= 768
    };
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    numOfSelectedOptions() {
      return this.sections.reduce((acc, section) => acc + (section.selectedOption ? 1 : 0), 0);
    },

    toggleOption(sectionIndex, option) {
      const section = this.sections[sectionIndex];
      section.selectedOption = section.selectedOption === option ? null : option;
      this.filterResults();
    },

    filterResults() {
      this.loading = true;
      const selectedOptions = this.sections.map(section => section.selectedOption);

      // if all options are null, show no results
      if (selectedOptions.every(option => option === null)) {
        this.showResults = false;
        this.bestMatches = [];
        this.perfectMatch = [];
        this.loading = false;
        return;
      }

      this.showResults = true;

      const filteredData = this.jsonData.data.map(item => {
        const score = {
          budget: 0,
          bikeType: 0,
          terrain: 0,
          wind: 0,
        };

        // Budget
        if (selectedOptions[0] === 'No Preference' || selectedOptions[0] === null) {
          score.budget = 0;
        } else if (selectedOptions[0] === '$500-$800' && item.price >= 500 && item.price <= 800) {
          score.budget = 1;
        } else if (selectedOptions[0] === '$800-$1200' && item.price > 800 && item.price <= 1200) {
          score.budget = 1;
        } else if (selectedOptions[0] === '>$1200' && item.price > 1200) {
          score.budget = 1;
        }

        // Bike Type
        if (selectedOptions[1] === 'Road Rim' && item.DiscOrRim === 'Rim') {
          score.bikeType = 1;
        } else if (selectedOptions[1] === 'Road Disc' && item.DiscOrRim === 'Disc' && item.frontRimInnerWidth <= 23) {
          score.bikeType = 1;
        } else if (selectedOptions[1] === 'Gravel Disc' && item.DiscOrRim === 'Disc' && item.frontRimInnerWidth > 23 && item.frontRimInnerWidth <= 28) {
          score.bikeType = 1;
        } else if (selectedOptions[1] === 'Mountain Disc' && item.DiscOrRim === 'Disc' && item.frontRimInnerWidth > 28) {
          score.bikeType = 1;
        } else if (selectedOptions[1] === null) {
          score.bikeType = 0;
        }

        // Terrain
        if (selectedOptions[2] === 'Flat' && item.frontRimDepth >= 50) {
          score.terrain = 1;
        } else if (selectedOptions[2] === 'Hilly' && item.frontRimDepth < 40 && item.frontRimDepth > 0) {
          score.terrain = 1;
        } else if (selectedOptions[2] === 'Mixed' && item.frontRimDepth >= 40 && item.frontRimDepth < 50) {
          score.terrain = 1;
        } else if (selectedOptions[2] === null) {
          score.terrain = 0;
        }

        item.score = score;
        item.totalScore = Object.values(score).reduce((a, b) => a + b, 0);
        return item;
      });

      filteredData.sort((a, b) => b.totalScore - a.totalScore);

      // logic for matches 
      // perfect match -> TOTAL SCORE = numOfSelectedOptions()
      // if more than 5 perfect matches, show all perfect matches
      // if less than 5 perfect matches, show all perfect matches and fill the rest with best matches (total 5 sorted by score)
      // if no perfect matches, show best matches (total 5 sorted by score)
      

      this.perfectMatch = filteredData.filter(item => item.totalScore === this.numOfSelectedOptions());
      console.log(filteredData)
      if(this.perfectMatch.length > 5) {
        this.bestMatches = [];
      } else {
        this.bestMatches = filteredData.filter(item => item.totalScore < this.numOfSelectedOptions()).slice(0, 5 - this.perfectMatch.length);
      }

      this.loading = false;
    },

    filterValue(value) {
      return value === -1 || value === '-1' ? 'N/A' : value;
    },

    formatPrice(price) {
      return price === -1 || price === '-1' ? 'N/A' : `$${price}`;
    },
  },
  async mounted() {
    document.title = "Wheelset Finder";
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = "https://www.pandapodium.cc/wp-content/uploads/2023/05/Panda_Podium_LogoFavicon.png";
    window.addEventListener('resize', this.handleResize);

    // Fetch JSON data
    try {
      const response = await wheelsetService.getAllWheelsets();
      this.jsonData = { data: response.data.data.results };
      console.log(this.jsonData);
    } catch (error) {
      console.error('Error fetching JSON:', error);
    }
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
.mainCont {
  font-family: 'Poppins', sans-serif;
}
.section-row {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 16px;
}
.section-icon, .section-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.options-container {
  display: flex;
}
.option-col {
  flex: 1;
}
.option-btn {
  width: 100%;
  color: #666;
  font-weight: 500;
  background-color: #e0e0e0;
}
.option-btn.selected {
  color: white;
  background-color: black;
}

.section-row, .match-row {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 16px;
}
/* .best-matches-section {
  border: 1px solid #333;
  padding: 16px;
  border-radius: 8px;
} */
.match-option {
  color: white;
  font-weight: bold;
}

@media (max-width: 768px) {
  .options-container {
    flex-wrap: wrap;
  }

  .main-content {
    width: 100% !important;
  }
  .title-container, .main-content-container {
    width: 100% !important;
  }
  .section-row {
    flex-direction: column;
    flex-wrap: wrap !important;
  }
  .section-icon, .section-title, .options-container {
    /* flex: 1 1 100%; */
    /* max-width: 100%; */
  }
  .option-col {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
</style>