import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import WheelsetCompare from '../views/WheelsetCompare.vue';
import AdminRegister from '../views/AdminRegister.vue';
import AdminLogin from '../views/AdminLogin.vue';
import AdminPage from '../views/AdminPage.vue';
import WheelsetFinder from '../views/WheelsetFinder.vue';

import authService from '@/services/authService';

const routes = [
  { path: '/', component: Home },
  { path: '/wheelsetCompare/:ppWheelsetId?', component: WheelsetCompare, props: true },
  { path: '/ppauth/adminLogin', component: AdminLogin},
  { path: '/ppauth/adminRegister', component: AdminRegister},
  { path: '/ppauth/admin', component: AdminPage, meta: { requiresAuth: true }},
  { path: '/wheelsetFinder', component: WheelsetFinder }, 
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to,from,next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    let response;

    try {
      response = await authService.checkAuthUser();
      if(response.status === 200) {
        next();
        return;
      }
    } catch (error) {
      next({path: '/ppauth/adminLogin', query: {redirect: to.fullPath}});
    }



    // authService.checkAuthUser().then((response) => {
    //   if(response.status === 200) {
    //     next();
    //   } else {
    //     // if the user is not authenticated, redirect to login with query param
    //     next({path: '/ppauth/adminLogin', query: {redirect: to.fullPath}});
    //   }
    // })
  } else {
    next();
}
}

)

export default router;